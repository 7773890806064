////
/// ============================================================================
/// HEADER
///
/// 01. Variables
/// 02. Header
/// ============================================================================
///
/// @group layout
////

/**
 * Variables
 */
$header-height-mobile: 60px;
$header-height-desktop: 120px;
$header-height-desktop-fixed: 120px;

/**
 * Header
 *
 * [1] WebKit Hardware Acceleration
 */
.header {
    @include relative(top 0, 9999);
    backface-visibility: hidden; // [1]
    height: $header-height-mobile;
    transform: translate3d(0, 0, 0); // [1]
    transition: background 0.3s;
    width: 100%;
    @include MQ(l) {
        height: $header-height-desktop;
    }

    &--fixed {
        position: fixed;
        top: -$header-height-mobile;
        transition: transform 0.3s;
        @include MQ(l) {
            height: $header-height-desktop-fixed;
            top: -$header-height-desktop-fixed;
        }
    }
    &--visible {
        transform: translate3d(0, 100%, 0);
    }
    &.is-header-open {
        background: transparent;
    }

    // IE9 Hack
    @at-root {
        .no-csstransforms3d {
            .header {
                transform: translateY(0%);
                &--visible {
                    transform: translateY(100%);
                }
            }
        }
    }

    &__headline {
        &-wrap {
            align-items: center;
            display: flex;
            height: $header-height-mobile;
            justify-content: space-between;
            position: relative;
            @include MQ(l) {
                height: $header-height-desktop;
                .header--fixed & {
                    height: $header-height-desktop-fixed;
                }
            }
        }
    }

    // Logo
    &__logo {
        flex: 1 1 auto;
        margin: 0;
        svg {
            height: 100%;
            width: 100%;
        }

        .c-logo {
            font-size: 0;
            &__default {
                height: 70px;
                width: 240px;
                display: none;
                @include MQ(l) {
                    display: block;

                    .header--fixed & {
                        display: none;
                    }
                }
            }
            &__small {
                height: 40px;
                width: 170px;
                @include MQ(l) {
                    display: none;

                    .header--fixed & {
                        display: block;
                    }
                }
            }
        }
    }

    // Navigation
    &__nav {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;

        // Trigger event
        input[type=checkbox] {
            display: none;
            & + label {
                display: block;
                @include MQ(l) {
                    display: none;
                }
            }
            &:checked + label + ul {
                display: block;
            }
        }

        .o-trigger {
            $trigger-size: 30px;
            $trigger-line: 2px;

            @include center(right, $trigger-size, $trigger-size);
            cursor: pointer;
            transition-duration: 0.5s;

            &__icon {
                @include absolute(top ($trigger-size / 2));
                background-color: color-get(gray-darker);
                height: $trigger-line;
                transition-duration: 0.5s;
                width: ($trigger-size / 2);
                &:before,
                &:after {
                    background-color: color-get(gray-darker);
                    content: '';
                    height: $trigger-line;
                    position: absolute;
                    transition-duration: 0.5s;
                    width: ($trigger-size / 2);
                }
                &:before {
                    top: (($trigger-size / -2) / 2) - 1px;
                }
                &:after {
                    top: (($trigger-size / 2) / 2) + 1px;
                }

                &--left {
                    left: 0;
                }
                &--right {
                    left: ($trigger-size / 2);
                }
            }

            // Trigger event
            .header__nav input[type=checkbox]:checked + & {
                .o-trigger__icon {
                    background: transparent;
                    transition-duration: 0.5s;
                    &--left {
                        &:before {
                            transform: rotateZ(45deg) scaleX(1.4) translate(($trigger-line / 2), ($trigger-line / 2));
                        }
                        &:after {
                            transform: rotateZ(-45deg) scaleX(1.4) translate(($trigger-line / 2), ($trigger-line / -2));
                        }
                    }
                    &--right {
                        &:before {
                            transform: rotateZ(-45deg) scaleX(1.4) translate(($trigger-line / -2), ($trigger-line / 2));
                        }
                        &:after {
                            transform: rotateZ(45deg) scaleX(1.4) translate(($trigger-line / -2), ($trigger-line / -2));
                        }
                    }
                }
            }
        }

        // Menu
        .o-menu {
            $menu-height: 50px;
            @extend %heading-fontfamily;
            @include absolute(top 60px right 0 left 0); // Height Header Mobile
            background: color-get(tertiary);
            display: none;
            font-weight: fontweight-get(extra);
            width: 100%;
            @include MQ(l) {
                background: transparent;
                display: flex;
                flex-wrap: nowrap;
                font-size: 18px;
                height: $menu-height;
                justify-content: center;
                position: static;
            }

            // li
            &__item {
                position: relative;
                // Dropdown
                &.has-children {
                    & > a:after {
                        @include center(right 1em, 6px, 10px);
                        @include triangle(rgba(#fff, 0.5), 6px, 10px, bottom);
                        content: '';
                        display: inline-block;
                        @include MQ(l) {
                            @include relative(top -3px right -0.4em);
                            border-top-color: rgba(#000, 0.3);
                            margin-top: 0;
                        }
                    }
                    &:hover .o-menu__sub-menu {
                        display: block;
                        left: 0;
                        right: 0;
                        @include MQ(l) {
                            min-width: 280px;
                        }
                    }
                    &:not(:first-child):hover .o-menu__sub-menu {
                        left: -1px;
                    }
                }

                &.is-first-level {
                    float: none;
                    width: 100%;
                    @include MQ(l) {
                        float: left;
                        height: 100%;
                        width: auto;
                    }

                    //&:hover > a,
                    //.o-menu:not(:hover) > &.is-active > a {
                    //    background: color-get(tertiary, $lighten: -10%);
                    //    @include MQ(l) {
                    //        background: transparent;
                    //    }
                    //}

                    // Area riservata
                    &:last-of-type {
                        display: flex;
                        flex: 1 1 auto;
                        float: none;
                        justify-content: flex-end;
                    }
                }
            }

            // a
            &__link {
                color: #fff;
                display: block;
                line-height: $menu-height;
                position: relative;

                &:not(.c-btn) {
                    padding: 0 1em;
                    @include MQ(l) {
                        color: color-get(text);
                        height: 100%;
                    }
                }

                &.is-active,
                &.is-parent,
                &.is-ancestor {
                    color: color-get(primary) !important;
                }

                &.c-btn {
                    border-radius: 0;
                    width: 100%;
                    @include MQ(l) {
                        color: #fff;
                        border-radius: 5rem;
                        width: auto;
                    }
                }
            }

            // Second Level
            &__sub-menu {
                background: color-get(gray-darker);
                display: none;
                font-size: 15px;
                line-height: $menu-height;
                margin: 0;
                padding-top: 0;
                @include MQ(l) {
                    @include absolute(top 100%);
                    border-radius: $border-radius;
                    padding: 1em;
                    &:before {
                        @include absolute(top -6px left 2em);
                        @include triangle(color-get(gray-darker), 6px, 10px, top);
                        content: '';
                        display: block;
                    }
                }
                .o-menu__link {
                    border-top: 1px solid rgba(#fff, 0.4);
                    padding: 0 1em;
                    @include MQ(l) {
                        color: #fff;
                        border-top: none;
                    }
                }
                .o-menu__item {
                    @include MQ(l) {
                        &:first-of-type a {
                            border-top-left-radius: $border-radius;
                            border-top-right-radius: $border-radius;
                        }
                        &:last-of-type a {
                            border-bottom-left-radius: $border-radius;
                            border-bottom-right-radius: $border-radius;
                        }
                    }
                    a:hover,
                    ul:not(:hover) &.is-active a {
                        background: color-get(tertiary);
                        @include MQ(l) {
                            color: color-get(primary);
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}