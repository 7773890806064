////
/// ============================================================================
/// COMPONENTS
///
/// 01. Alert
/// 02. Logo
/// 03. Vcard
/// 04. Arrow
/// 05. Avatar
/// 06. Social
/// 07. Breadcrumb
/// 08. Back To Top
/// 09. Prev Next Post Navigation
/// 10. Pagination
/// ============================================================================
///
/// @group components
////

/**
 * Alert
 *
 * Success, Info, Warning, Error
 */
.c-alert {
    background: color-get(gray-light);
    border-left: 0.5em solid rgba(#000, 0.15);
    padding: 1rem 1.5em;
    position: relative;
    width: 100%;

    &, a {
        color: color-get(text);
    }

    ul, ol {
        list-style: none;
        padding: 0;
    }

    $alerts: success, info, warning, error;

    @each $alert in $alerts {
        $background: color-get($alert);

        &--#{$alert} {
            background: $background;
            color: color-contrast($background);
        }
    }

    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
}


/**
 * Logo
 */
.c-logo {
    color: color-get(gray-darker);
    display: flex;
    align-items: center;
    line-height: 100%;
}


/**
 * Vcard
 */
.c-vcard {
    &__block {}
    .opening {
        display: block;
        span {
            display: inline-block;
        }
        &__day {
            width: 30%;
        }
        &__hours {
            width: 68%;
        }
    }
    [itemprop="telephone"],
    [itemprop="email"] {
        &, &:hover {
            color: color-get(text);
            text-decoration: none;
        }
    }
}


/**
 * Arrow
 */
.c-arrow {
    @include fluid-type(height width, 4rem, 5rem);
    align-items: center;
    display: flex;
    justify-content: center;
    svg {
        @include fluid-type(height width, 1.5rem, 2rem);
    }
    &--negative {
        background: #fff;
        color: color-get(primary);
    }
    &--primary {
        background: color-get(primary);
        color: #fff;
    }
    &--secondary {
        background: color-get(secondary);
        color: #fff;
    }
}


/**
 * Avatar
 */
.c-avatar {
    @include aspect-ratio(1 1);
    overflow: hidden;
    position: relative;
    img {
        @include transform();
        max-width: none;
    }
}


/**
 * Social
 */
.c-social {
    @include fixed(bottom 0 left 0, 100);
    align-items: stretch;
    display: flex;
    height: 40px;
    justify-content: center;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    transform: translateY(100px);
    transition: transform 0.5s;
    width: 100%;
    @include MQ(m) {
        height: auto;
        position: relative;
        transform: none;
    }

    &.is-show {
        transform: translateY(0);
    }

    &__item {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        margin-right: 0;
        text-align: center;

        @include MQ(m) {
            flex: 0 0 auto;
            &.whatsapp {
                display: none;
            }
        }

        &:last-child {
            margin-right: 0;
        }
        a {
            align-items: center;
            color: #fff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: auto;
            height: 100%;
            text-align: center;
            width: 100%;

            @include MQ(m) {
                background: transparent;
                border: 1px solid currentColor;
                border-radius: 60px;
                color: color-get(gray);
                height: 60px;
                margin-left: 3px;
                margin-right: 3px;
                width: 60px;
                &:hover {
                    background: transparent;
                }
            }
        }
        svg {
            height: 30px;
            width: 30px;
        }

        // Color Map
        // @link http://brandcolors.net/
        $socials: (
            facebook: #3b5998,
            //feed: #f62,
            twitter: #55acee,
            //youtube: #f00,
            whatsapp: #075e54,
            linkedin: #0077b5
        );

        @each $name, $color in $socials {
            &.#{$name} a {
                background: $color;
                &:hover {
                    background: darken($color, 10%);
                }
            }
            @include MQ(m) {
                &.#{$name} a {
                    background: transparent;
                    &:hover {
                        background: transparent;
                        border-color: $color;
                        color: $color;
                    }
                }
            }
        }
    }
}


/**
 * Breadcrumb
 */
.c-breadcrumb {
    @extend .type-xs;
    opacity: 0.9;

    &__wrap {
        span {
            align-items: center;
            display: flex;
            overflow: hidden;
            padding-left: .5em;
            white-space: nowrap;
        }
        & > span {
            justify-content: center;
            padding-left: 0;
            & > span > a {
                flex: 0 0 auto;
                min-width: auto;
            }
        }
        a, strong {
            flex: 0 1 auto;
            min-width: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
        a {
            color: color-get(gray);
            padding-right: .5em;
            text-decoration: none;
            &:hover {
                color: color-get(primary);
                flex: 0 0 auto;
                min-width: auto;
            }
        }
        strong {
            color: color-get(gray-dark);
            font-weight: fontweight-get(regular);
            padding-left: .5em;
        }
    }
}


/**
 * Back To Top
 */
.c-backtotop {
    $size: 50px;
    @include fixed(bottom $size right gutter(), 99);
    background: color-get(primary);
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: #fff;
    display: inline-block;
    height: $size;
    opacity: 0;
    transition: opacity .3s 0s, visibility 0s .3s, background .3s 0s;
    visibility: hidden;
    width: $size;
    svg {
        @include transform();
        height: ($size / 2);
        width: ($size / 2);
    }

    @include MQ(m) {
        $size: 60px;
        bottom: ($size / 2);
        height: $size;
        right: ($size / 2);
        width: $size;
        svg {
            height: ($size / 2);
            width: ($size / 2);
        }
    }
    @include MQ(l) {
        $size: 70px;
        bottom: ($size / 2);
        height: $size;
        right: ($size / 2);
        width: $size;
        svg {
            height: ($size / 2);
            width: ($size / 2);
        }
    }

    &.c-backtotop--show,
    &.c-backtotop--fade-out,
    &:hover {
        transition: opacity 0.3s 0s, visibility 0s 0s, background .3s 0s;
    }

    // the button becomes visible
    &.c-backtotop--show {
        opacity: 1;
        visibility: visible;
    }

    // if the user keeps scrolling down, the button is out of focus and becomes less visible
    &.c-backtotop--fade-out {
        opacity: 0.7;
    }

    &:hover {
        color: #fff;
        opacity: 1;
    }
}


/**
 * Prev Next Post Navigation
 */
.c-prev-next {
    &__list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }
    &__item {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        @include MQ(m) {
            margin-right: gutter();
            width: span(5);
            &:last-of-type {
                margin-right: 0;
            }
        }
        a {
            align-items: center;
            color: #fff;
            display: flex;
            justify-content: flex-start;
            padding: 1rem 0;
            height: 100%;
            width: 100%;
            &:hover {
                color: #fff;
            }
        }
        &--cat {
            display: none;
            @include MQ(m) {
                display: flex;
                width: span(2);
            }
            a {
                justify-content: center;
            }
            span {
                display: none;
            }
        }
        svg {
            height: 38px;
            margin: 0.5em;
            width: 38px;
        }
        figure {
            flex: 0 0 auto;
            margin-right: 1.5em;
            width: span(3);
            @include MQ(m) {
                width: span(2 of 5);
            }
        }
        h5 {
            color: #fff;
        }
    }
}


/**
 * Pagination
 */
.c-pagination {
    @include clearfix;
    text-align: center;
    &__current,
    &__url,
    &__dots {
        @include aspect-ratio(1 1);
        align-items: center;
        color: color-get(text);
        display: inline-flex;
        justify-content: center;
        margin-left: -1px;
        position: relative;
        vertical-align: middle;
        width: 3em;
    }
    &__url {
        border: 1px solid color-get(gray-light);
        color: color-get(text);
        cursor: pointer;
        &:hover {
            color: color-get(primary);
            border-color: color-get(primary);
            z-index: 1;
        }
    }
    &__current {
        background: color-get(primary);
        border: 1px solid color-get(primary);
        color: #fff;
    }
}


/**
 * Software
 */
.c-software {
    &__text {
        @include MQ(l) {
            width: span(5);
        }
        .btn {
            text-transform: capitalize;
        }
    }
    &__image {
        @include MQ(l) {
            height: 600px; // Organic Height
            width: span(7);
            // Remove aspect-ratio
            &:before {
                display: none;
            }
        }
    }
}


/**
 * Organic Shape
 */
.c-organic {
    @include transform();
    height: 100%;
    max-height: 600px;
    max-width: 600px;
    width: 100%;

    &__shape {
        @include transform();
        background-repeat: repeat;
        background-size: 500%;
        height: 100%;
        width: 100%;
        will-change: border-radius, background;

        &--1 {
            animation: organic-shape-bg 30s linear infinite forwards, organic-shape-morph-1 25s linear infinite forwards;
            background: #ccc;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
        }
        &--2 {
            animation: organic-shape-bg 30s linear infinite forwards, organic-shape-morph-2 35s linear infinite forwards;
            background: #ccc;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
        }
        &--3 {
            animation: organic-shape-morph-1 50s linear infinite forwards;
            background-position: 50% 50%;
            background-size: cover;
            height: 90%;
            width: 90%;
            z-index: 1;
        }
    }
}

@keyframes organic-shape-bg {
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
}

@keyframes organic-shape-morph-1 {
    0% { border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%; }
    10% { border-radius: 39% 61% 38% 62% / 53% 54% 46% 47%; }
    20% { border-radius: 56% 44% 52% 48% / 57% 35% 65% 43%; }
    30% { border-radius: 41% 59% 45% 55% / 33% 56% 44% 67%; }
    40% { border-radius: 46% 54% 60% 40% / 55% 74% 26% 45%; }
    50% { border-radius: 44% 56% 22% 78% / 31% 74% 26% 69%; }
    60% { border-radius: 31% 69% 32% 68% / 31% 71% 29% 69%; }
    70% { border-radius: 44% 56% 60% 40% / 63% 36% 64% 37%; }
    80% { border-radius: 46% 54% 38% 62% / 44% 48% 52% 56%; }
    90% { border-radius: 66% 34% 57% 43% / 49% 51% 49% 51%; }
    100% { border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%; }
}

@keyframes organic-shape-morph-2 {
    0% { border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%; }
    10% { border-radius: 66% 34% 57% 43% / 49% 51% 49% 51%; }
    20% { border-radius: 46% 54% 38% 62% / 44% 48% 52% 56%; }
    30% { border-radius: 44% 56% 60% 40% / 63% 36% 64% 37%; }
    40% { border-radius: 31% 69% 32% 68% / 31% 71% 29% 69%; }
    50% { border-radius: 44% 56% 22% 78% / 31% 74% 26% 69%; }
    60% { border-radius: 46% 54% 60% 40% / 55% 74% 26% 45%; }
    70% { border-radius: 41% 59% 45% 55% / 33% 56% 44% 67%; }
    80% { border-radius: 56% 44% 52% 48% / 57% 35% 65% 43%; }
    90% { border-radius: 39% 61% 38% 62% / 53% 54% 46% 47%; }
    100% { border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%; }
}


/**
 * Submenu
 */
.c-submenu {
    display: inline-block;
    font-size: 0;
    margin: 0;
    padding: 0 gutter();
    li {
        border-bottom: 1px solid color-get(gray-light);
        display: inline-block;
        font-size: 0.8rem;
        list-style: none;
        outline: none;
        text-transform: uppercase;
        &:first-child {
            padding-left: 0;
        }
        a {
            color: color-get(text, $lighten: 20%);
            display: block;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 4px;
            margin-top: 2px;
            padding: 9px 1.5em 11px;
            position: relative;
            text-align: left;
            text-decoration: none;
            z-index: 0;
        }
        &.is-current a {
            color: color-get(primary);
            cursor: default;
            pointer-events: none;
            z-index: 10;
            &:after {
                border-bottom: 1px solid color-get(primary);
                bottom: -5px;
                content: '';
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
    }
}


/**
 * Gutenberg Blocks
 */

// Feature
.c-blockFeature {
    &__text {
        @include MQ(l) {
            width: span(5);
        }
        .btn {
            margin-top: 1rem;
        }
    }
    &__image {
        @include MQ(l) {
            width: span(7);
        }
    }
}

// Accordion
.c-blockAccordion {
    &__item {
        margin-bottom: -1px;
        transition: all 0.6s;
        &:last-child {
            border-bottom: 1px solid color-get(gray-light);
        }

        &.is-open {
            border-bottom: none;
            .c-blockAccordion__title {
                color: color-get(primary);
            }
            .c-blockAccordion__icon {
                color: color-get(primary);
                svg.minus {
                    opacity: 1;
                }
                svg.plus {
                    opacity: 0;
                }
            }
        }
    }

    &__header {
        align-items: center;
        background: #fff;
        border-top: 1px solid color-get(gray-light);
        cursor: pointer;
        display: flex;
        padding: 1.8rem 0 1.2rem;
        position: relative;
    }
    &__content {
        background: #fff;
        border-bottom: 1px solid color-get(gray-light);
        &-inner {
            padding: 1rem 42px 3rem 42px;
            p {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__title {
        flex: 1 1 auto;
        margin: 0;
        padding-left: 10px;
    }
    &__icon {
        flex: 0 0 auto;
        height: 32px;
        position: relative;
        width: 32px;
        svg {
            @include absolute(top 0 left 0);
            height: 100%;
            width: 100%;
            &.minus {
                opacity: 0;
            }
        }
    }
}

// Cover
.c-blockCover {
    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
    &__wrap {
        border-radius: $border-radius;
        position: relative;
        overflow: hidden;
    }
    &__text {
        color: #fff;
        padding-left: 3em;
        padding-right: 3em;
        position: relative;
        z-index: 1;
        h2 {
            color: currentColor;
        }
        .alignwide & {
            padding-left: span(2) + gutter();
            padding-right: span(2) + gutter();
        }
    }
    &__image {
        background-color: color-get(tertiary);
        img {
            opacity: 0.2;
        }
    }
}

// File
.c-blockFile {
    list-style: none;
    margin: 0;
    padding-left: 0;
    &__item {
        border-top: 1px solid color-get(gray-light);
        &:first-child {
            border-top: 0;
        }
    }
}

/**
 * Toc
 */
.c-toc {
    &__title {
        margin-bottom: 0.5rem;
    }
    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -1rem;
        margin-right: -1rem;
    }
    &__item {
        border-bottom: color-get(gray-light) 1px solid;
        color: color-get(primary);
        display: flex;
        flex: 1 1 auto;
        font-size: 0.8em;
        padding: 0.5em 0;
        line-height: 1.25;
        margin: 0 1rem;
        width: 100%;
        @include MQ(s) {
            $size: (100% / 2);
            max-width: calc(#{$size} - 2rem);
        }
        @include MQ(m) {
            $size: (100% / 3);
            max-width: calc(#{$size} - 2rem);
        }
    }
}
