////
/// ============================================================================
/// TYPOGRAPHY
///
/// 01. Base Font
/// 02. Links
/// 03. Typography
/// 04. Navigation
/// 05. Citation
/// ============================================================================
///
/// @group layout
////

/**
 * Base Font
 */
html {
    @include fluid-type(font-size, 16px, 22px);
    @extend %body-fontfamily;
    font-weight: fontweight-get(regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;

    // Scrollbar
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        background: #fff;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: color-get(primary);
    }
}



/**
 * Links
 */
a {
    color: $link-color;
    text-decoration: $link-decoration;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}


/**
 * Typography
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @extend %heading-fontfamily;
    color: color-get(gray-darker);
    font-weight: fontweight-get(extra);
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 1rem;
    strong {
        font-weight: fontweight-get(extra);
    }
}

h1, h2,
.h1, .h2 {
    font-weight: fontweight-get(black);
    margin-bottom: 2rem;
    strong {
        font-weight: fontweight-get(black);
    }
}

.h1, h1 {
    @include fluid-type(font-size, 38px, 78px);
    line-height: 1;
}
.h2, h2 {
    @include fluid-type(font-size, 30px, 60px);
}
.h3, h3 {
    @include fluid-type(font-size, 26px, 40px);
}
.h4, h4  {
    @include fluid-type(font-size, 20px, 24px);
    line-height: 1.4;
}
.h5, h5 {
    @include fluid-type(font-size, 18px, 22px);
}
.h6, h6 {
    @include fluid-type(font-size, 14px, 16px);
}

.type-xs {
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
}
.type-s {
    @include fluid-type(font-size line-height, 13px, 15px);
    text-transform: uppercase;
}


blockquote,
dl,
form,
ol,
p,
pre,
table,
ul {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

p,
div,
ul, ol {
    &.alignleft {
        text-align: left;
    }
    &.alignright {
        text-align: right;
    }
    &.aligncenter {
        text-align: center;
    }
}

em {
    font-style: italic;
}
strong {
    font-weight: fontweight-get(medium);
}

/**
 * Navigation
 */
nav {
    ul, ol {
        @include reset(list);
    }
    a, a:hover {
        text-decoration: none;
    }
}


/**
 * Citation
 */
blockquote {
    background: color-get(gray-light, $lighten: 10%);
    font-style: italic;
    font-weight: fontweight-get(bold);
    padding: 1.5rem 1.5rem 1.5rem 4.5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    position: relative;

    @include MQ(l) {
        padding-left: 3.5rem;
    }

    svg {
        $size: 64px;
        @include absolute(top 0.5rem left 0.5rem, 1);
        content: '';
        display: block;
        height: $size;
        width: $size;
        fill: color-get(primary);
        @include MQ(l) {
            left: ($size / -2);
        }
    }
    p {
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}