////
/// ============================================================================
/// FOOTER
///
/// 01. Footer
/// ============================================================================
///
/// @group layout
////

/**
 * Footer
 */
.footer {
    color: #fff;
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
        .c-logo {
            color: #fff;
            height: 40px;
            width: 170px;
        }
        h4 {
            color: #fff;
        }
        @include MQ(s) {
            text-align: left;
        }
    }
    &__logo {
        width: 100%;
        @include MQ(s) {
            flex: 1 1 auto;
            width: auto;
        }
        svg {
            margin: auto;
            @include MQ(s) {
                margin: 0;
            }
        }
    }
    &__menus {
        display: flex;
        flex: 2 1 auto;
        flex-wrap: wrap;
        justify-content: space-between;
        .o-sitemap {
            flex: 1 1 auto;
            @include MQ(s) {
                padding-right: 1.5em;
            }
            ul {
                opacity: 0.7;
            }
        }
    }
    &__contacts {
        flex: 2 1 auto;
        .o-vcard {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            opacity: 0.7;
            &__block {
                flex: 1 1 auto;
                @include MQ(s) {
                    padding-right: 1.5em;
                }
            }
        }
    }

    &__bottom {
        text-align: center;
        &, a {
            color: color-get(primary, 0.55, 15%);
        }
    }
}