////
/// ============================================================================
/// HOME
///
/// 01. Home
/// 02. Spotlight
/// 03. Course
/// 04. Therapy
/// 05. Pathology
/// ============================================================================
///
/// @group pages
////

/**
 * Home
 */
body.home {}


/**
 * Software
 */
.home-software {
    .swiper-pagination {
        bottom: 0;
        position: relative;
    }
}


/**
 * Areas
 */
.home-areas {}


/**
 * Post
 */
.home-post {
    &__header {
        text-align: center;
    }
    &__body {
        &.swiper-container-initialized {
            .u-box {
                flex-shrink: 0;
                height: 100%;
                position: relative;
                transition-property: transform;
                width: 100%;
            }
        }
        &:not(.swiper-container-initialized) .u-box {
            @include MQ(m) {
                flex: 1 1 100%;
                margin-right: gutter();
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}


/**
 * Testimonials
 */
.home-testimonials {
    &__header {
        text-align: center;
    }
    &__body {
        height: 100%;
        width: 100%;
    }
}