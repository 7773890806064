////
/// ============================================================================
/// OBJECTS
///
/// 01. Grid
/// 02. Split
/// 03. Spinner
/// 04. Entry
/// 05. Spotlight
/// 06. Pathology
/// ============================================================================
///
/// @group components
////

/**
 * Grid
 */
.o-grid {
	@include fluid-type(padding-right padding-left, 1rem, 2rem);
	margin: 0 auto;
	max-width: $container;

	&__wrap {
		@if ($debug == true) {
			background: susy-svg-grid() no-repeat scroll;
			outline: 1px solid green;
		}
	}
}


/**
 * Split
 */
.o-split {
	@include MQ(m) {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__column {
		position: relative;
		@include MQ(m) {
			width: span(6);
		}

		figure {
			text-align: center;
		}
	}

	&--reverse {
		@include MQ(m) {
			flex-direction: row-reverse;
		}
	}

	&__content {
		position: relative;
	}
}


/**
 * Spinner
 */
.o-spinner {
	animation-animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-fill-mode: none;
	animation-iteration-count: infinite;
	animation-name: spinner;
	animation-play-state: running;
	animation-timing-function: linear;
	border: 4px solid transparent;
	border-left: 4px solid color-get(primary);
	border-radius: 50%;
	border-top: 4px solid color-get(primary);
	cursor: wait;
	display: block;
	opacity: 1;
	pointer-events: none;
	transition: all 200ms cubic-bezier(0.72, 0.01, 0.56, 1);
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/**
 * Menu
 */
.o-menu {
	@include reset(list);
}


/**
 * Entry
 */
// Default
.o-entry {
	display: flex; // Impostato per altezza strech con Swiper
	height: auto; // Impostato per evitare l'height 100 di Swiper
	margin-bottom: 2rem;
	&__link {
		@include absolute(top 0 left 0, 10);
		cursor: pointer;
		height: 100%;
		width: 100%;

		&:hover + .o-entry__wrap {
			.o-entry__title,
			.o-entry__readmore {
				color: color-get(primary);
			}
		}
	}

	&__wrap {
	}

	&__content {
	}

	&__title {
		margin-bottom: 0;
	}

	&__title,
	&__readmore {
		transition: color 0.25s ease-out;
	}

	&__abstract {
		color: color-get(text, 0.6);
	}

	&__readmore {
		@extend .type-s;
		margin-bottom: 0;
		margin-top: 2rem;
	}
}

// Testimonial
.o-testimonial {
	margin-bottom: 2rem;

	&__logo {
		max-width: 230px;
	}

	&__text {
		color: color-get(text, 0.6);
	}

	&__author {
		h4 {
			margin-bottom: 0;
		}

		small {
			@extend .type-s;
			color: color-get(text, 0.4);
		}
	}
}


/**
 * Attachement
 */
.o-attach {
	@include fluid-type(padding-top padding-bottom, 1rem, 2rem);
	align-items: center;
	color: color-get('text');
	display: flex;
	text-decoration: none;

	&:hover {
		color: color-get('text');
		text-decoration: none;

		h4 {
			color: color-get(primary);
		}

		.o-file::before {
			background: color-get(primary);
		}
	}

	&__icon {
		flex: 0;
		margin-right: 1em;
	}

	&__details {
		flex: 1;

		h4 {
			margin: 0;
		}
	}
}


/**
 * File Icon
 */
.o-file {
	background: linear-gradient(45deg, #eee 0%, #fff 100%);
	border: 1px solid color-get(gray);
	color: black;
	display: block;
	height: 60px;
	position: relative;
	width: 50px;

	&::before {
		@include absolute(bottom 12px left -4px);
		background: color-get(gray-dark);
		color: #fff;
		content: 'file';
		content: attr(filetype);
		font-size: 11px;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: 11px;
		padding: 3px 6px;
		text-transform: uppercase;
	}

	&::after {
		@include absolute(top -2px right -2px);
		border-color: white transparent transparent color-get(gray);
		border-style: solid;
		border-width: 16px 0 0 15px;
		content: '';
		display: block;
		height: 0;
		width: 0;
	}
}
