////
/// ============================================================================
/// VARIABLES
/// ============================================================================
///
/// @group config
////

/**
 * Debug
 */
$debug: false;


/// Font Family
///
/// @prop {Type} body       ["Arial, Helvetica, sans-serif"]                        - Body Text
/// @prop {Type} heading    ["'Open Sans', Arial, Helvetica, sans-serif"]           - Heading h1, h2, etc
/// @prop {Type} mono       ["Menlo, Monaco, Consolas, 'Courier New', monospace"]   - Code
///
/// @example scss - Usage
/// .foo {
///     font-family: fontfamily-get(heading);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-family: 'Open Sans', Arial, Helvetica, sans-serif;
/// }
///
/// @type Map
$fontfamily: (
    body        : "'Brother 1816', Helvetica, Arial, sans-serif",
    heading     : "'Brother 1816', Helvetica, Arial, sans-serif",
    mono        : "Menlo, Monaco, Consolas, 'Courier New', monospace"
) !global;


/// Font Weight
///
/// @prop {Type} thin       100
/// @prop {Type} light      300
/// @prop {Type} normal     400
/// @prop {Type} bold       700
/// @prop {Type} heavy      900
///
/// @example scss - Usage
/// .foo {
///     font-weight: fontweight-get(regular);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-weight: 400;
/// }
///
/// @type Map
$fontweight: (
    thin    : null,
    light   : null,
    book    : null,
    regular : 400,
    medium  : 500,
    bold    : 700,
    extra   : 800,
    black   : 900
) !global;


/**
 * Breakpoints & Grid
 *
 * - s .......... 480px Default for extra small devices, phone
 * - m .......... 768px Default for small devices, tablets
 * - l .......... 992px Default for medium devices, desktops
 * - xl ......... 1200px Default for large devices, wide screens
 *
 * Per ciascun breakpoint vengono settati:
 *
 * - query ...... media query
 * - susy ....... personalizzazioni della griglia
 */
$susy: (
    'columns': susy-repeat(12),
    'gutters': 1/2,
    'spread': 'narrow',
    'container-spread': 'narrow',
    'svg-grid-colors': hsl(180, 50%, 80%)
);

$container: 1680px;


/**
 * Breakpoint
 *
 * @link http://breakpoint-sass.com/
 */
@include bkpt-set('to ems', true);

$breakpoints: (
    s: 540px,
    m: 800px,
    l: 1200px,
    xl: 1600px
) !global;


/// Mappa colori
///
/// @prop {Color} body          [#fff]      - body
/// @prop {Color} text          [#767677]   - text
/// @prop {Color} gray-darker   [#1e1e1f]   - gray darker
/// @prop {Color} gray-dark     [#767677]   - gray dark
/// @prop {Color} gray          [#b1b0af]   - gray
/// @prop {Color} gray-light    [#c2c0c0]   - gray light
/// @prop {Color} gray-lighter  [#d4d3d2]   - gray lighter
/// @prop {Color} success       [#5cb85c]   - success
/// @prop {Color} info          [#5bc0de]   - info
/// @prop {Color} warning       [#f0ad4e]   - warning
/// @prop {Color} danger        [#d9534f]   - danger
/// @prop {Color} primary       [#222838]   - primary
/// @prop {Color} secondary     [#e31a4e]   - secondary
/// @prop {Color} tertiary      [#71bdb4]   - tertiary
///
/// @example scss - Usage
/// .foo {
///     color: color-get(body);
/// }
/// .bar {
///     color: color-get(gray, 0.5, -10%);
/// }
///
/// @example css - CSS Output
/// .foo {
///     color: #fff;
/// }
/// .bar {
///     color: hsla(0, 0%, 52%, .5);
/// }
///
/// @type Map
$colors: (
    body            : #fff,
    text            : #5c5e6c,

    gray-darker     : #3d404f,
    gray-dark       : #5c5e6c,  // Titoli
    gray            : #c5c6cd,  //
    gray-light      : #e5e7ed,  // Bordi
    gray-lighter    : #f7f8fa,  // Sfondi

    success         : #21d492,
    info            : #3d9cd2,
    warning         : #fcd34a,
    error           : #fa3d56,

    primary         : #4eb9fd,
    secondary       : #70c59a,
    tertiary        : #3542a1,
) !global;


/**
 * Links
 */
$link-color                 : color-get(primary) !global;
$link-hover-color           : color-get(primary, $lighten: -10%) !global;
$link-visited-color         : color-get(primary) !global;
$link-decoration            : none !global;
$link-hover-decoration      : underline !global;
$link-visited-decoration    : none !global;


/**
 * Border radius
 */
$border-radius: 8px !global;


/**
 * Form
 */
$form-spacing               : 1;

$form-label-color           : color-get(text);
$form-label-font-weight     : fontweight-get(regular);
$form-label-line-height     : inherit;

$input-color                : color-get(text);
$input-placeholder-color    : color-get(text, $lighten: 20%);
$input-cursor-disabled      : default;
$input-font-family          : inherit;
$input-background           : #fff;
$input-background-focus     : #fff;
$input-background-disabled  : color-get(gray, $lighten: 20%);
$input-border-size          : 1px;
$input-border-style         : solid;
$input-border-color         : color-get(gray);
$input-border-color-focus   : color-get(primary);
$input-number-spinners      : false;
$input-radius               : 50px;
$input-shadow               : none;
$input-shadow-focus         : 0 0 5px rgba(#000, 0.1);
$input-transition           : box-shadow 0.5s, border-color 0.25s ease-in-out;

$select-triangle-color      : #333;
