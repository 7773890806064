@charset "UTF-8";

////
/// ============================================================================
/// FOUNDATION v. 28092017
///
/// 01. Default
/// 02. Typography
/// 03. Media
/// 04. Form
/// ============================================================================
///
/// @link http://goo.gl/t9A0dO
/// @link http://necolas.github.io/normalize.css/
///
/// @group base
////

/**
 * Default
 */
html {
    box-sizing: border-box;
    font: 16px/1 sans-serif;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    @include debug(breakpoints);
    background: color-get(body);
    color: color-get(text);
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
    box-sizing: inherit; // http://www.paulirish.com/2012/box-sizing-border-box-ftw/
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block;
}


/**
 * Typography
 */
blockquote,
dt,
figure {
    margin: 0;
    padding: 0;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    margin-top: 0;

    a,
    a:hover,
    a:visited {
        text-decoration: none;
    }
}

address {
    font-style: inherit;
}

a {
    background-color: transparent;
    color: inherit;
    transition: 0.3s;
    -webkit-text-decoration-skip: objects;
}

::-moz-focus-inner {
    padding: 0;
    border: 0;
}

:focus {
    outline: 0;
}

b,
strong {
    font-weight: inherit;
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}




/**
 * Media
 *
 * 01. Get rid of gap under images by making them display: inline-block; by default
 * 02. Google Maps breaks if `max-width: 100%` acts upon it;
 *     use their selector to remove the effects.
 *     https://github.com/lumapps/lumX/blob/master/core/scss/base/_images.scss
 * 03. SVG Alignment: https://cloudfour.com/thinks/our-svg-icon-process/
 */
img {
    height: auto;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;

    &[data-sizes="auto"] {
        display: block;
        width: 100%;
    }

    &[src*="svg"] {
        height: 100%;
        width: 100%;
    }

    .gm-style & {
        max-width: none; /* 02 */
    }
}

audio,
embed,
iframe,
img,
object,
video {
    display: inline-block; /* 01 */
    border: 0;
    max-width: 100%;
}

svg {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    position: relative;
    top: -0.0625em; /* 03 */
    vertical-align: middle;
    width: 1em;

    &:not(:root) {
        overflow: hidden; // Bug Fix IE9
    }
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}


/**
 * Form
 *
 * Normalize Version 7.0.0
 */
button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    border: 0;
    padding: 0;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}