////
/// ============================================================================
/// GUTEMBERG
/// ============================================================================
///
/// @group vendors
////

.has-2-columns {
    align-items: center;
    .wp-block-image {
        text-align: center;
    }
}


.wp-block-button__link {
}



