////
/// ============================================================================
/// FORM
///
/// 01. Input, Textarea
/// 02. Textarea
/// 03. Input, Textarea disabled/readonly
/// 04. Number Input
/// 05. Select
/// 06. Checkbox/Radio
/// 07. File
/// 08. Label
/// ============================================================================
///
/// @group utils
////

/**
 * Input, Textarea
 */
#{text-inputs()},
textarea {
    @include fluid-type(height, 2rem, 3rem);
    @include fluid-type(padding-top padding-bottom, 0.5rem, 1rem);
    background-color: $input-background;
    border: $input-border-size $input-border-style $input-border-color;
    border-radius: $input-radius;
    box-shadow: $input-shadow;
    color: $input-color;
    display: block;
    font-family: $input-font-family;
    padding-left: 1em;
    padding-right: 1em;
    transition: $input-transition;
    width: 100%;

    &:focus {
        background-color: $input-background-focus;
        border: $input-border-size $input-border-style $input-border-color-focus;
        box-shadow: $input-shadow-focus;
        outline: none;
        transition: $input-transition;
    }
}


/**
 * Textarea
 */
textarea {
    border-radius: ($input-radius / 2);
    max-width: 100%;
    resize: vertical;
    &[rows] {
        height: auto;
    }
}


/**
 * Input, Textarea disabled/readonly
 */
input,
textarea {
    &::placeholder {
        color: $input-placeholder-color;
    }

    &:disabled,
    &[readonly] {
        cursor: $input-cursor-disabled;
    }
}


/**
 * Number Input
 */
#{text-inputs(number)} {
    @if not($input-number-spinners) {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}


/**
 * Select
 */
select {
    @include fluid-type(height, 3rem, 4rem);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $input-background;
    border: $input-border-size $input-border-style $input-border-color;
    border-radius: $input-radius;
    box-shadow: $input-shadow;
    color: $input-color;
    font-family: $input-font-family;
    line-height: normal;
    padding: 0 1em;
    transition: $input-transition;
    width: 100%;

    &:focus {
        background-color: $input-background-focus;
        border: $input-border-size $input-border-style $input-border-color-focus;
        box-shadow: $input-shadow-focus;
        outline: none;
        transition: $input-transition;
    }

    //@if $select-triangle-color != transparent {
    //    @include triangle-background($select-triangle-color);
    //    background-size: 9px 6px;
    //    background-position: right center;
    //    background-origin: content-box;
    //    background-repeat: no-repeat;
    //}

    // Disabled state
    &:disabled {
        cursor: $input-cursor-disabled;
    }

    // Hide the dropdown arrow shown in newer IE versions
    &::-ms-expand {
        display: none;
    }

    &[multiple] {
        height: auto;
    }
}


/**
 * Checkbox/Radio
 */
[type="checkbox"],
[type="radio"] {
    position: absolute;
    top: 3px;
    // Styles for input/label siblings
    + label {
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 0;
        padding-left: 20px;
        line-height: normal;
        vertical-align: top;
    }
    label > & {
        margin-right: 0.5em;
    }
}


/**
 * File
 */
[type="file"] {
    width: 100%;
}


/**
 * Label
 */
label {
    color: $form-label-color;
    display: block;
    font-size: 15px;
    font-weight: $form-label-font-weight;
    line-height: $form-label-line-height;
    margin: 0;
    position: relative;
}


/**
 * Base
 */
.form {
    &__group {
        margin-bottom: 1rem;
        position: relative;
        &--privacy {
            display: block;
            padding-left: 1.5em;
            input {
                left: -1.5em;
            }
        }
    }
    &__label {
        font-size: 16px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }
    &__actions {
        @include clearfix;
        text-align: center;
    }
    .required {
        display: none;
    }
    &__fieldset {
        &--columns {
            .form__group {
                float: left;
                margin-right: 2%;
                width: 49%;
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    .sent & {
        display: none;
    }

    // Attachment
    &__attachment {
        @include fluid-type(padding, 2rem, 3rem);
        background: color-get(gray-light);
        border: 1px dashed color-get(gray);
        display: flex;
        justify-content: center;

        // Style wrapping span as button
        span.wpcf7-form-control-wrap.your-attachment {
            background: color-get(primary);
            border: solid 1px color-get(primary);
            border-radius: 40px;
            color: #FFF;
            display: inline-block;
            height: 50px;
            overflow: hidden;
            position: relative;
            width: 150px;
        }

        // Made input big and move it left and top inside wrapper to hide actual control but leave input clickable
        input.wpcf7-form-control.wpcf7-file {
            @include absolute(right 0 bottom 0);
            height: 1000px;
            outline: none !important;
            width: 1500px;
        }

        // Add button text
        .wpcf7-file:before {
            @include absolute(right 0 bottom 0);
            color: #fff;
            content: "Allega file…";
            display: block;
            font-size: 18px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            width: 150px;
        }
    }


    &--login {
        .login-username,
        .login-password {
            @include MQ(m) {
                display: inline-block;
                margin: 0 2% 0 0;
                width: 48%;
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
        .login-remember {
            clear: both;
            [type=checkbox] {
                position: relative;
                top: -2px;
            }
        }
        .login-submit {
            text-align: center;
        }
        ul {
            @include reset(list);
            font-size: 0;
            li {
                display: inline-block;
                font-size: 15px;
                &:not(:nth-child(1)):before {
                    content: '-';
                    display: inline-block;
                    margin: 0 5px;
                }
            }
        }
    }
}


/**
 * Required
 */
.required {
    @extend .type-s ;
    display: block;
    color: color-get(error);
    @include MQ(s) {
        @include absolute(top 10px right 0);
    }
}