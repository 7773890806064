////
/// ============================================================================
/// ARCHIVE
///
/// 01. Default
/// 02. Testimonials
/// 03. Software
/// ============================================================================
///
/// @group pages
////

.archive {
    &__header {
        text-align: center;
    }
}


/**
 * Default
 *
 * Utilizzato per PostType: Post e Private
 */
.archive-default {
    &__list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .o-entry {
            margin-right: gutter();
            width: 100%;
            &:last-of-type {
                margin-right: 0;
            }
            @include MQ(m) {
                margin-right: gutter();
                width: span(6);
                &:nth-of-type(2n),
                &:last-of-type {
                    margin-right: 0;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(2n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
            @include MQ(l) {
                width: span(4);
                &,
                &:nth-of-type(2n) {
                    margin-right: gutter();
                }
                &:nth-of-type(3n),
                &:last-of-type {
                    margin-right: 0;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(3n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
        }

        // Swiper
        .swiper-container-horizontal & {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        &.swiper-wrapper {
            box-sizing: border-box;
        }
    }

    // Swiper
    .swiper-pagination {
        bottom: 0;
        position: relative;
    }
}


/**
 * Testimonials
 */
.archive-testimonials {
    &__list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .o-testimonial {
            margin-right: gutter();
            width: 100%;
            &:last-of-type {
                margin-right: 0;
            }
            @include MQ(m) {
                margin-right: gutter();
                width: span(6);
                &:nth-of-type(2n),
                &:last-of-type {
                    margin-right: 0;
                }
                .u-box__wrap {
                    width: auto;
                }

                // Swiper
                .swiper-container-horizontal & {
                    &,
                    &:nth-of-type(2n) {
                        margin-right: gutter();
                    }
                    &:last-of-type {
                        margin-right: 0!important;
                    }
                }
            }
        }

        // Swiper
        .swiper-container-horizontal & {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        &.swiper-wrapper {
            box-sizing: border-box;
        }
    }

    // Swiper
    .swiper-pagination {
        bottom: 0;
        position: relative;
    }
}


/**
 * Software
 */
.archive-software {
    &__list {
        .c-software {
            @include MQ(m) {
                &:nth-of-type(odd) .o-split {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}