////
/// ============================================================================
/// SINGULAR
///
/// 01. Singular
/// 02. Layout
/// 03. Comments
/// ============================================================================
///
/// @group pages
////

/**
 * Singular
 */
body.single,
body.page {}


/**
 * Layout
 */
.single {
    &__header {
        text-align: center;
        p {
            color: color-get(gray);
        }
        @include MQ(l) {
            margin-right: auto;
            margin-left: auto;
            width: span(10);
            p {
                padding-left: span(2 of 10 narrow);
                padding-right: span(2 of 10 narrow);
            }
        }
        .o-user {
            font-weight: fontweight-get(bold);
            text-transform: capitalize;
        }
    }
    &__image {
        @include MQ(l) {
            margin-left: auto;
            margin-right: auto;
            width: span(10);
        }
    }
    &__body {
        @include MQ(l) {
            margin-right: auto;
            margin-left: auto;
            width: span(8);
        }

        // Typography
        h2, h3, h4, h5, h6,
        p, ul, ol {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        h2 {
            margin-bottom: 1.5rem;
            margin-top: 3.5rem;
        }
        h3 {
            margin-bottom: 1.5rem;
            margin-top: 2.5rem;
        }
        .alignleft {
            margin: 0 0.5rem 0.5rem 0;
        }
        .aligright {
            margin: 0 0 0.5rem 0.5rem;
        }

        // Gutenberg
        .wp-block-image,
        .wp-block-embed,
        .wp-block-gallery {
            @extend .u-leading-s;
            @extend .u-trailing-s;
            text-align: center;
            width: auto;
        }
        .wp-block-image {
            max-width: none;
        }
        .wp-block-embed {
            @include MQ(l) {
                $grid: -(span(2 of 8) + gutter(2 of 8));
                margin-left: $grid;
                margin-right: $grid;
            }
        }
        .wp-block-gallery {
            @include MQ(l) {
                $grid: -(span(2 of 8) + gutter(2 of 8));
                margin-left: calc(#{$grid} - 8px);
                margin-right: calc(#{$grid} - 8px);
            }
        }
        .alignwide {
            @include MQ(l) {
                $grid: -(span(2 of 8) + gutter(2 of 8));
                margin-left  : $grid;
                margin-right : $grid;
            }
        }
        .alignfull {
            @include MQ(l) {
                margin-left: calc(-100vw / 2 + 100% / 2);
                margin-right: calc(-100vw / 2 + 100% / 2);
                max-width: 100vw;
            }
        }
    }
    &__share {}
}


/**
 * Comments
 */
.comments {
    &__header,
    &__body {
        @include MQ(l) {
            margin-right: auto;
            margin-left: auto;
            width: span(8);
        }
    }
}


/**
 * Cta
 */
.cta {
    &__contacts {}
    &__form {
        border-radius: $border-radius;
    }
}