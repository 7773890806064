////
/// ============================================================================
/// CONTACT FORM 7
/// ============================================================================
///
/// @group vendors
////

.wpcf7-not-valid-tip {
    color: color-get(error);
    display: none;
}

.screen-reader-response {
    &[role="alert"] {
        @extend .c-alert;
        @extend .c-alert--warning;
        margin-bottom: 2rem;
    }
}

/* Alert */
.wpcf7-validation-errors,
.wpcf7-mail-sent-ng,
.wpcf7-mail-sent-ok {
    @extend .c-alert;
    margin-top: 1rem;
}

.wpcf7-validation-errors,
.wpcf7-mail-sent-ng {
    @extend .c-alert--error;
}

.wpcf7-mail-sent-ok {
    @extend .c-alert--success;
}

/* Loading */
.wpcf7 .ajax-loader {
    @include center(right, 32px, 32px);
    @extend .o-spinner;
    visibility: hidden;
    &.is-active {
        visibility: visible;
    }
}

.wpcf7 div.ajax-error {
    display: none;
}

.grecaptcha-badge {
    display: none;
}