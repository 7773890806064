////
/// ============================================================================
/// FONTS
/// ============================================================================
///
/// @link https://prowebtype.com/delivering-web-fonts/
/// @link https://jonsuh.com/blog/font-loading-with-font-events/
///
/// @group abstracts
////

/**
 * Roboto
 */
@font-face {
    font-family: "Brother1816Local";
    src: local('Brother 1816');
    font-weight: fontweight-get(regular);
    font-style: normal;
}
@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-Regular.woff2") format("woff2"),
    url("fonts/Brother-1816-Regular.woff") format("woff");
    font-weight: fontweight-get(regular);
    font-style: normal;
}
@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-RegularItalic.woff2") format("woff2"),
    url("fonts/Brother-1816-RegularItalic.woff") format("woff");
    font-weight: fontweight-get(regular);
    font-style: italic;
}
@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-Medium.woff2") format("woff2"),
         url("fonts/Brother-1816-Medium.woff") format("woff");
    font-weight: fontweight-get(medium);
    font-style: normal;
}
@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-Bold.woff2") format("woff2"),
    url("fonts/Brother-1816-Bold.woff") format("woff");
    font-weight: fontweight-get(bold);
    font-style: normal;
}@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-ExtraBold.woff2") format("woff2"),
    url("fonts/Brother-1816-ExtraBold.woff") format("woff");
    font-weight: fontweight-get(extra);
    font-style: normal;
}
@font-face {
    font-family: "Brother 1816";
    src: url("fonts/Brother-1816-Black.woff2") format("woff2"),
    url("fonts/Brother-1816-Black.woff") format("woff");
    font-weight: fontweight-get(black);
    font-style: normal;
}

/**
 * Font Family Placeholder
 */
.body-fontfamily,
%body-fontfamily {
    font-family: "Brother1816Local", sans-serif;
    html.fonts-loaded & {
        font-family: fontfamily-get(body);
    }
}
.heading-fontfamily,
%heading-fontfamily {
    font-family: "Brother1816Local", sans-serif;
    html.fonts-loaded & {
        font-family: fontfamily-get(heading);
    }
}
%mono-fontfamily {
    font-family: fontfamily-get(mono);
    font-weight: fontweight-get(regular);
}